<template>
    <div class="app">
        <form class="auth-form" @keypress.enter.prevent>
            <h1>Create Account</h1>
            <div class="auth-site-name">afkadvantage.com</div>
            
            <div class="auth-input">
                <label for="inputEmail">Email Address</label>
                <input v-model="email" type="email" placeholder="Email Address" id="inputEmail">
            </div>

            <div class="auth-input">
                <label for="inputUsername">Username</label>
                <input v-model="username" type="name" placeholder="Username" id="inputUsername">
            </div>

            <div class="auth-input">
                <label for="inputPassword">Password</label>
                <input v-model="password" type="password" placeholder="Password" id="inputPassword">
            </div>

            <div class="auth-input" style="margin-top: 50px;">
                <button type="button" v-on:click="register" class="button-primary-2" v-bind:disabled="isLoading">Sign up</button>
            </div>

            <div class="auth-switch-site">Already have an account? <router-link to="/auth/login">Sign in</router-link></div>
        </form>
    </div>
</template>

<script>

import Api from '../../scripts/api';
import Toast from '../../scripts/toast';

export default {
    data() {
      return {
        email: '',
        password: '',
        username: '',
        isLoading: false
      }
    },
    methods: {
      register: async function() {
        
        if(!Api.validation.validateEmail(this.email)) {
          return Toast.error('Email is invalid.');
        }
        if(!Api.validation.validateUsername(this.username)) {
          return Toast.error('Username must be at least 6 characters.');
        }
        if(!Api.validation.validatePassword(this.password)) {
          return Toast.error('Password must be at least 6 characters.');
        }

        this.isLoading = true;
        try {

          var response = await Api.authRegister(this.email, this.password, this.username);
          if(response.data.success) {
            window.location.replace("/");
          } else {
            switch(response.data.reason) {
              case 0: Toast.error('Invalid email address provided.'); break;
              case 1: Toast.error('Invalid username provided.'); break;
              case 2: Toast.error('Invalid password provided.'); break;
              case 3: Toast.error(`The email '${this.email}' is already registered.`); break;
              case 4: Toast.error(`The username '${this.username}' is already taken.`)
            }
            this.isLoading = false;
          }

        } catch (ex) {
          console.log(ex);
          Toast.error('Unexpected error occured, try again.')
          this.isLoading = false;
        }
      }
    }
}
</script>

<style scoped>
  @import '../../assets/css/auth.css';
</style>